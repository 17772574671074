import Glide from '@glidejs/glide'

const sliderEl = document.getElementById('page_slider')

if (sliderEl) {
  const loadImage = (slideEl) => {
    return new Promise(resolve => {
      const img = new Image()
      img.onload = function() {
        slideEl.style.backgroundImage = `url('${img.src}')`
        resolve()
      }
      img.src = slideEl.getAttribute('data-image')
    })
  }

  const promises = []
  const slides = sliderEl.querySelectorAll('.page_slider-slide')
  for (var i = 0, l = slides.length; i < l; i++) {
    promises.push(
      loadImage(slides[i])
    )
  }
  Promise.all(promises).then(() => {
    sliderEl.classList.add('fade-loader')
    setTimeout(() => {
      sliderEl.classList.remove('loading')
      sliderEl.classList.remove('fade-loader')
      new Glide(sliderEl.querySelector('.glide'), {
        type: 'carousel',
        gap: 0,
        autoplay: 6000
      }).mount()  
    }, 500)
  })
}
